import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getOnboardingSeedData,
  getOnboardingData,
  saveOnboardingData,
  inviteUsers,
  getPeers,
} from "src/web-services";
import { OnboardingCreators, OnboardingTypes } from "../actions";

const authTokenSelector = state => state.user.authToken;

function* requestGetOnboardingSeedData(action) {
  try {
    const { userId } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getOnboardingSeedData,
      authToken,
      userId,
    );
    yield put(
      OnboardingCreators.getOnboardingSeedDataSuccess(response.data),
    );
  } catch (error) {
    yield put(OnboardingCreators.getOnboardingSeedDataFailure());
  }
}

function* requestGetOnboardingData(action) {
  try {
    const { userId } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getOnboardingData, authToken, userId);
    yield put(
      OnboardingCreators.getOnboardingDataSuccess(response.data),
    );
  } catch (error) {
    yield put(OnboardingCreators.getOnboardingDataFailure());
  }
}

function* requestGetPeers(action) {
  try {
    const { userId } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getPeers, authToken, userId);
    yield put(
      OnboardingCreators.getPeersSuccess(response.data.peers),
    );
  } catch (error) {
    yield put(OnboardingCreators.getPeersFailure());
  }
}

function* requestSaveOnboardingData(action) {
  try {
    const { userId, data } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      saveOnboardingData,
      authToken,
      userId,
      data,
    );
    yield put(
      OnboardingCreators.getOnboardingDataSuccess(response.data),
    );
  } catch (error) {
    yield put(OnboardingCreators.getOnboardingDataFailure());
  }
}

function* requestInviteUsers(action) {
  try {
    const { data } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(inviteUsers, authToken, data);
    yield put(OnboardingCreators.inviteUsersSuccess(response.data));
  } catch (error) {
    yield put(OnboardingCreators.inviteUsersFailure());
  }
}

export function* watchOnboardingRequests() {
  yield takeEvery(
    OnboardingTypes.GET_ONBOARDING_SEED_DATA_REQUEST,
    requestGetOnboardingSeedData,
  );
  yield takeEvery(
    OnboardingTypes.GET_ONBOARDING_DATA_REQUEST,
    requestGetOnboardingData,
  );
  yield takeEvery(OnboardingTypes.GET_PEERS_REQUEST, requestGetPeers);
  yield takeEvery(
    OnboardingTypes.SAVE_ONBOARDING_DATA_REQUEST,
    requestSaveOnboardingData,
  );
  yield takeEvery(
    OnboardingTypes.INVITE_USERS_REQUEST,
    requestInviteUsers,
  );
}
