import { createReducer } from "reduxsauce";
import { OnboardingTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.onboarding;

export const getOnboardingSeedDataFailure = state => ({
  ...state,
  loadingOnboardingSeedData: false,
});
export const getOnboardingSeedDataRequest = state => ({
  ...state,
  loadingOnboardingSeedData: true,
});
export const getOnboardingSeedDataSuccess = (state, action) => ({
  ...state,
  onboardingSeedData: action.onboardingSeedData,
  loadingOnboardingSeedData: false,
});

export const getOnboardingDataFailure = state => ({
  ...state,
  loadingOnboardingData: false,
});
export const getOnboardingDataRequest = state => ({
  ...state,
  loadingOnboardingData: true,
});
export const getOnboardingDataSuccess = (state, action) => ({
  ...state,
  onboardingData: action.onboardingData,
  loadingOnboardingData: false,
});

export const getPeersFailure = state => ({
  ...state,
});
export const getPeersRequest = state => ({
  ...state,
});
export const getPeersSuccess = (state, action) => {
  return {
    ...state,
    peers: action.peers,
  };
};

export const saveOnboardingDataFailure = state => ({
  ...state,
  loadingOnboardingData: false,
});
export const saveOnboardingDataRequest = state => ({
  ...state,
  loadingOnboardingData: true,
});
export const saveOnboardingDataSuccess = state => ({
  ...state,
  loadingOnboardingData: false,
});

export const inviteUsersFailure = state => ({
  ...state,
});
export const inviteUsersRequest = state => ({
  ...state,
});
export const inviteUsersSuccess = state => ({
  ...state,
});

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_ONBOARDING_SEED_DATA_FAILURE]:
    getOnboardingSeedDataFailure,
  [Types.GET_ONBOARDING_SEED_DATA_REQUEST]:
    getOnboardingSeedDataRequest,
  [Types.GET_ONBOARDING_SEED_DATA_SUCCESS]:
    getOnboardingSeedDataSuccess,

  [Types.GET_ONBOARDING_DATA_FAILURE]: getOnboardingDataFailure,
  [Types.GET_ONBOARDING_DATA_REQUEST]: getOnboardingDataRequest,
  [Types.GET_ONBOARDING_DATA_SUCCESS]: getOnboardingDataSuccess,

  [Types.GET_PEERS_FAILURE]: getPeersFailure,
  [Types.GET_PEERS_REQUEST]: getPeersRequest,
  [Types.GET_PEERS_SUCCESS]: getPeersSuccess,

  [Types.SAVE_ONBOARDING_DATA_FAILURE]: saveOnboardingDataFailure,
  [Types.SAVE_ONBOARDING_DATA_REQUEST]: saveOnboardingDataRequest,
  [Types.SAVE_ONBOARDING_DATA_SUCCESS]: saveOnboardingDataSuccess,

  [Types.INVITE_USERS_FAILURE]: inviteUsersFailure,
  [Types.INVITE_USERS_REQUEST]: inviteUsersRequest,
  [Types.INVITE_USERS_SUCCESS]: inviteUsersSuccess,

  inviteUsersFailure: null,
  inviteUsersRequest: ["data"],
  inviteUsersSuccess: null,
};

export default createReducer(INITIAL_STATE, HANDLERS);
