import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getOnboardingSeedData(authToken, userId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/users/${userId}/user_onboarding/seed_data`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getOnboardingData(authToken, userId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/users/${userId}/user_onboarding`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getPeers(authToken, userId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/users/${userId}/user_onboarding/peers`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function saveOnboardingData(
  authToken,
  userId,
  onboardingData,
) {
  const data = {
    university_rep_onboarding: onboardingData,
  };
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/${userId}/user_onboarding`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function inviteUsers(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/users/bulk_invite`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}
